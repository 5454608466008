import { render, staticRenderFns } from "./ImportantMatterList.vue?vue&type=template&id=319474ca&"
import script from "./ImportantMatterList.vue?vue&type=script&lang=js&"
export * from "./ImportantMatterList.vue?vue&type=script&lang=js&"
import style0 from "./ImportantMatterList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDataTable,VIcon,VLayout,VList,VListItem,VListItemAction,VMenu,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/imaiyuurika/sj_contract_procedure_contractor_front_mirai/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('319474ca')) {
      api.createRecord('319474ca', component.options)
    } else {
      api.reload('319474ca', component.options)
    }
    module.hot.accept("./ImportantMatterList.vue?vue&type=template&id=319474ca&", function () {
      api.rerender('319474ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/agency/ImportantMatterList.vue"
export default component.exports